<template>
  <!-- 行驶数据 -->
  <div ref="container" class="chart" :class="{
    [$style.chart]: true,
    [$style.large]: large
  }"></div>
</template>

<style module lang="scss">
.chart {
  height: 6rem;

  &.large {
    height: 15rem;
  }
}
</style>

<script>
import { Chart } from "@antv/g2";

const { remUnit = 20 } = window;

export default {
  props: {
    title: {
      type: String,
    },
    large: {
      type: Boolean,
      default: false,
    },
    vData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chart: undefined
    };
  },
  mounted() {
    this.initChart('statDate', 'driveMiles', this.vData);
  },
  watch: {
    vData(val) {
      this.initChart('statDate', 'driveMiles', val);
    }
  },
  methods: {
    initChart(xAxis, yAxis, data) {
      const { container } = this.$refs;
      const { large } = this;
      let { chart } = this;

      if (!data || data.length === 0) return;

      if (chart) {
        chart.changeData(data);

        return;
      }

      chart = new Chart({
        container,
        autoFit: true,
        padding: [(large ? 1.5 : 0.5) * remUnit, remUnit, 1.5 * remUnit, 2 * remUnit],
      });

      chart.scale({
        [xAxis]: {
          range: [0, 1],
        },
        [yAxis]: {
          min: 0,
          nice: true,
        },
      });

      chart.tooltip({
        showCrosshairs: true,
        shared: true,
        customContent: (name, items) => {
          const container = document.createElement("div");
          container.className = "g2-tooltip";
          const title = `<div class="custom-tooltip"><h4>${name}</h4>`;
          let listItem = "";
          items.forEach((item) => {
            listItem += `<p>${item.value}km</p>`;
          });
          container.innerHTML = title + listItem + "</div>";

          return container;
        }
      });

      chart.axis(xAxis, {
        grid: {
          line: {
            style: {
              stroke: "#343B74",
            },
          },
        },
        line: null,
        tickLine: null,
        label: {
          style: {
            fontSize: (large ? 0.7 : 0.6) * window.remUnit,
            fill: "#5961A6",
          },
          formatter: (val) => {
            return val.replace(/\d{4}-/, '');
          },
        },
      });
      chart.axis(yAxis, {
        grid: null,
        label: {
          style: {
            fontSize: (large ? 0.7 : 0.6) * window.remUnit,
            fill: "#5961A6",
          },
        },
      });

      chart.area().position(`${xAxis}*${yAxis}`).style({
        fillOpacity: 1,
        fill: "l(270) 1:#FF3C5F 0:#2C315D",
      }).shape('smooth');
      chart.line().position(`${xAxis}*${yAxis}`).style({
        stroke: "#FF3C5F",
      }).shape('smooth');

      chart.data(data);
      chart.render();
      this.chart = chart;
    }
  },
};
</script>